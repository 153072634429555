<template>
    <div class="container">
        <div>
            <el-button type="primary" @click="add" style="font-size: 24px;">填写今天的观测数据</el-button>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 20px;">
            <div>
                <el-date-picker
                    v-model="date"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                >
                </el-date-picker>
                <el-button type="primary" style="margin-left: 20px;" @click="search">查询</el-button>
            </div>
            <div>
                <el-button type="primary" style="margin-left: 20px;" @click="download" >导出</el-button>
            </div>
        </div>

        <div style="margin-top: 20px;">
            <div>
                累计观测需达到50天以上,当前已观测记录:{{ alltotal }} 天
            </div>
            <div style="margin-top: 20px;">
                连续观测记录需达到12天以上(需节假日也无中断),此前已完成连续记录最长 {{ historyCount }} 天,当前已连续记录:{{ maxCount }} 天
            </div>
        </div>
        <!-- table -->
        <div style="margin-top: 20px;">
            <el-table :data="tableList" v-loading="dataListLoading"  style="width: 1490px">
                <el-table-column label="序号" width="50" type="index" header-align="center" align="center" fixed="left"></el-table-column>
                <el-table-column label="学校名称" prop="schoolName" width="200" header-align="center" align="center" fixed="left"></el-table-column>
                <el-table-column label="观测日期" prop="date" width="200" header-align="center" align="center" fixed="left"></el-table-column>
                <!-- 最高最低温度 -->
                <el-table-column label="昨日最高温度°C" prop="maxTem"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="出现时间" prop="maxTemTime"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="昨日最低温度°C" prop="minTem"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="出现时间" prop="minTemTime"  header-align="center" align="center" ></el-table-column>
                <!-- 最高最低湿度 -->
                <el-table-column label="昨日最高湿度%" prop="maxHumidity"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="出现时间" prop="maxHumidityTime"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="昨日最低湿度%" prop="minHumidity"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="出现时间" prop="minHumidityTime"  header-align="center" align="center" ></el-table-column>
                <el-table-column label="上午" header-align="center">
                    <el-table-column label="记录时间" prop="time1"></el-table-column>
                    <el-table-column label="温度°C" prop="tem1"></el-table-column>
                    <el-table-column label="湿度%"  prop="humidity1"></el-table-column>
                    <el-table-column label="气压hPa" prop="barometer1"></el-table-column>
                    <el-table-column label="风向°" prop="windDir1"></el-table-column>
                    <el-table-column label="风速m/s" prop="windSpeed1"></el-table-column>
                    <el-table-column label="降雨mm" prop="rain1"></el-table-column>
                    <el-table-column label="光照(w/m)" prop="solarRadiation1" width="100"></el-table-column>
                </el-table-column>
                <el-table-column label="下午" header-align="center">
                    <el-table-column label="记录时间" prop="time2"></el-table-column>
                    <el-table-column label="温度°C" prop="tem2"></el-table-column>
                    <el-table-column label="湿度%"  prop="humidity2"></el-table-column>
                    <el-table-column label="气压hPa" prop="barometer2"></el-table-column>
                    <el-table-column label="风向°" prop="windDir2"></el-table-column>
                    <el-table-column label="风速m/s" prop="windSpeed2"></el-table-column>
                    <el-table-column label="降雨mm" prop="rain2"></el-table-column>
                    <el-table-column label="光照(w/m)" prop="solarRadiation2"  width="100"></el-table-column>
                </el-table-column>
                <el-table-column label="观测体会" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">点击查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="最美瞬间" header-align="center" align="center">
                    <template slot-scope="scope">
                        <a :href="scope.row.photos" target="_blank">点击查看</a>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remark" width="200" header-align="center" align="center"></el-table-column>
                <el-table-column label="操作" header-align="center" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row)" type="text" size="small" :disabled="scope.row.date !== today">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="margin-top: 20px;">
            <el-pagination
                layout="prev, pager, next"
                :total="total"
                @current-change="currentChange"
                @prev-click="prevClick"
                @next-click="nextClick"
            >
            </el-pagination>
        </div>
        <reportData ref="report"></reportData>
    </div>
</template>

<script>
import { $activity } from "@/services" 
import reportData from "./components/reportData.vue"
import dayjs from "dayjs"
import $cookies from "vue-cookies";
import XLSX from 'xlsx';
export default {
    components:{
        reportData,
    },
    data() {
        return {
            userId:$cookies.get("userInfo").id,
            today:dayjs().format('YYYY-MM-DD'),
            dataListLoading:false,
            pageNum:1,
            pageSize:10,
            date:'',
            total:0,
            tableList:[],
            alltotal:0,
            maxCount:0,
            historyCount:0,
        }
    },
    created() {
        this.getTableList()
        $activity.days($cookies.get("userInfo").id).then(res => {
            this.alltotal = res.data.total
            this.historyCount = res.data.maxCount
            this.maxCount = res.data.count
        })
    },
    methods:{
        async getTableList() {
            if (!this.dataListLoading) {
                this.dataListLoading = true
                let params = {}
                params.pageNum = this.pageNum
                params.pageSize = this.pageSize
                params.date = this.date
                params.userId = this.userId
                let res = await $activity.reportList(params)
                if (res.code === 200) {
                    this.total = res.data.total
                    this.tableList = res.data.records
                }
                this.dataListLoading = false
            }
        },
        currentChange(e) {
            this.pageNum = e
            this.getTableList()
        },
        prevClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        nextClick(e) {
            this.pageNum = e
            this.getTableList()
        },
        handleClick(row) {
            console.log(row)
        },
        search() {
            this.pageNum = 1
            this.getTableList()
        },
        // 点击上传数据
        add() {
            if (this.tableList.length && this.tableList[0].date === this.today) {
                this.$message.error('今天已经上传过数据了,请点击修改!')
                return
            }
            this.$refs.report.setFlag({})
        },
        // 点击修改
        edit(row) {
            this.$refs.report.setFlag(row)
        },
        // 导出
        async download () {
            let field = [
                'schoolName', 'date', 'maxTem','maxTemTime','minTem',
                'time1','tem1','humidity1','barometer1','windDir1','windSpeed1','rain1','solarRadiation1' ,
                'time2','tem2','humidity2','barometer2','windDir2','windSpeed2','rain2','solarRadiation2' ,
            ]
            let label = {
                schoolName: "学校名称",
                date: "观测时间",
                maxTem: "昨日最高温度’C",
                maxTemTime:'出现时间',
                minTem:'昨日最低温',
                minTemTime:'出现时间',
                time1:'上午记录时间',
                tem1:'上午温度°C',
                humidity1:'上午湿度%',
                barometer1:'上午气压hPa',
                windDir1:'上午风向°',
                windSpeed1:'上午风速m/s',
                rain1:'上午降雨mm',
                solarRadiation1:'上午光照w/m',
                time2:'下午记录时间',
                tem2:'下午温度°C',
                humidity2:'下午湿度%',
                barometer2:'下午气压hPa',
                windDir2:'下午风向°',
                windSpeed2:'下午风速m/s',
                rain2:'下午降雨mm',
                solarRadiation2:'下午光照w/m'
            }
            let reportData = await $activity.reportList({pageNum:1,pageSize:99999,userId: this.userId,date:this.date}) 
            console.log(reportData)
            let lastCol = XLSX.utils.encode_col(field.length - 1)
            let lastRow = XLSX.utils.encode_row(reportData.data.records.length)
            const ws = XLSX.utils.json_to_sheet(reportData.data.records, {
                header: field
            })
            const range = XLSX.utils.decode_range(ws['!ref'])
            ws['!ref'] = `A1:${lastCol}${lastRow}`
            for (let c = range.s.c; c <= range.e.c; c++) {
                const header = XLSX.utils.encode_col(c) + '1'
                ws[header].v = label[ws[header].v]
            }
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, `${this.device_type}`)
            XLSX.writeFile(wb, `${this.tableList[0].schoolName}的观测记录.xlsx`)
        },
    }
}
</script>

<style lang="less" scoped>
.container {
    padding: 40px;
}
</style>