<template>
    <el-dialog
        title="选择设备"
        :visible="flag"
        :modal="false"
        width="300px"
    >
    <el-radio-group v-model="radio">
        <div v-for="(item,index) in sebeiList" :key="index" style="margin-top: 20px;">
            <el-radio :label="item.device" border>{{ item.deviceName }}</el-radio>
        </div>
    </el-radio-group>
    <span slot="footer" class="dialog-footer">
        <el-button @click="flag = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
import $cookies from "vue-cookies"
import { $activity } from "@/services"
export default {
    data() {
        return {
            sebeiList:[],
            flag:false,
            radio:'',
            type:null
        }
    },
    methods:{
        setFlag(type) {
            this.type = type
            this.flag = true
            this.getsebei()
        },
        // 获取设备列表 
        async getsebei() {
            let userName = $cookies.get("userInfo").userName
            let email =  $cookies.get("userInfo").email
            let userId =  $cookies.get("userInfo").id
            let params = {userName,email,userId}
            let {code,data} = await $activity.getList(params)
            if (code !== 200) return
            this.sebeiList = data
        },
        async submit() {
            if (this.radio) {
                this.$parent.$parent.autoData(this.type,this.radio)
                this.flag = false
            } else {
                this.$message.error('请选择一台设备!!')
            }

        },
        handleBefore(file) {
            if (file.size / 1024 / 1024 > 2) {
                this.$message.error('上传图片不能大于2M')
                this.fileList = []
                return
            }
        },
        hanleSuccess(response) {
            if(response.code === 200) {
                this.form.photos = response.data.url
            }
        },
    }
}
</script>